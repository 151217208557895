var render = function render(){var _vm=this,_c=_vm._self._c;return _c('c-box',{staticClass:"landing",attrs:{"mx":"5"}},[_c('c-heading',{attrs:{"fontSize":{ base: 'sm', lg: '2xl', xl: '4xl' },"as":"h1","display":"flex","alignItems":"baseline","mb":"4"}},[_c('c-text',{attrs:{"mr":"3"}},[_vm._v(" Growth Framework ")])],1),_c('c-stack',{attrs:{"spacing":10}},[_c('c-box',{attrs:{"borderRadius":"8px","bg":"#fff","boxShadow":"0 3px 6px rgba(186, 186, 186, 0.16)","p":"8","w":"100%","minHeight":"100px"}},[_c('c-heading',{attrs:{"as":"h3","display":"flex","fontSize":"xl","mb":"7"}},[_c('c-text',{attrs:{"fontWeight":"normal","mr":"2"}},[_vm._v(" Level of Business: ")]),_c('c-text',{attrs:{"fontWeight":"600"}},[_vm._v(_vm._s(_vm.companyLevel))])],1),_c('c-flex',{attrs:{"maxWidth":"80%","align":"center","h":"80px"}},[_c('ProgressBar',{directives:[{name:"chakra",rawName:"v-chakra",value:({
            flex: 1,
          }),expression:"{\n            flex: 1,\n          }"}],attrs:{"value":_vm.companyLevelPercentage,"height":'20px'}}),_c('svg',{directives:[{name:"chakra",rawName:"v-chakra",value:({
            w: '10',
            h: '10',
            m: '-10px',
            zIndex: '1',
          }),expression:"{\n            w: '10',\n            h: '10',\n            m: '-10px',\n            zIndex: '1',\n          }"}]},[_c('use',{attrs:{"href":require("@/assets/icons/icon-target.svg") + "#target"}})])],1)],1),(_vm.isFetchingTourData)?_c('c-box',[_c('ContentLoader',{attrs:{"viewBox":"0 0 250 130","primaryColor":"#ddd"}},[_c('rect',{attrs:{"x":"0","y":"0","rx":"3","ry":"3","width":"250","height":"10"}}),_c('rect',{attrs:{"x":"0","y":"20","rx":"3","ry":"3","width":"250","height":"10"}}),_c('rect',{attrs:{"x":"0","y":"40","rx":"3","ry":"3","width":"250","height":"10"}}),_c('rect',{attrs:{"x":"0","y":"60","rx":"3","ry":"3","width":"250","height":"10"}})])],1):_c('c-box',[_c('c-grid',{attrs:{"w":"100%","template-columns":{ base: '1fr', lg: `repeat(${_vm.tour.length}, 1fr)` },"gap":{ base: 6, lg: 8, xl: 14 }}},_vm._l((_vm.tour),function(stage){return _c('c-box',{key:stage.id,attrs:{"borderRadius":"8px","bg":_vm.activeStage === stage.stage ? '#fff' : '#EEF0F2',"boxShadow":"0 0px 6px rgba(186, 186, 186, 0.16)","p":"8","w":"100%","minHeight":"100px","cursor":"pointer"},on:{"click":function($event){return _vm.selectStage(stage.stage)}}},[_c('c-flex',{attrs:{"align":"center","justify":"space-between"}},[_c('c-text',{attrs:{"color":"vc-orange.400","fontSize":{ lg: 'base', xl: 'xl' },"fontWeight":"500"}},[_vm._v(" Stage "+_vm._s(stage.stage)+" ")]),_c('c-text',{attrs:{"color":"gray.400","fontSize":{ lg: 'base', xl: 'xl' },"fontWeight":"500"}},[_vm._v(" "+_vm._s(stage.time)+" ")])],1),_c('c-heading',{attrs:{"fontSize":{ lg: 'xl', xl: '2xl' },"fontWeight":"600","mt":"2","mb":"3"}},[_vm._v(" "+_vm._s(stage.title)+" ")]),_c('c-list',{attrs:{"maxWidth":"85%","mb":"6"}},_vm._l((stage.descriptions),function(description,index){return _c('c-list-item',{key:index},[_c('c-flex',{attrs:{"mb":"2"}},[_c('svg',{directives:[{name:"chakra",rawName:"v-chakra",value:({
                    w: '4',
                    h: '4',
                    mr: '2',
                  }),expression:"{\n                    w: '4',\n                    h: '4',\n                    mr: '2',\n                  }"}]},[_c('use',{attrs:{"href":require("@/assets/icons/icon-tools.svg") + "#tools"}})]),_c('c-text',{attrs:{"fontSize":"xs","flex":"1","color":"gray.400"}},[_vm._v(" "+_vm._s(description)+" ")])],1)],1)}),1),_c('c-flex',{attrs:{"bg":"#F8F8F8","borderRadius":"70px","h":"80px","align":{ lg: 'normal', xl: 'center', xs: 'center' },"justify":"space-between","px":"6","flexWrap":"wrap"}},[_c('c-pseudo-box',{attrs:{"as":"a","display":"flex","alignItems":"center","cursor":"pointer"},on:{"click":function($event){return _vm.onStageStartClick(stage)}}},[_c('c-box',{attrs:{"w":{ lg: 8, xl: 10, xs: 5 },"h":{ lg: 8, xl: 10, xs: 5 }}},[_c('svg',{directives:[{name:"chakra",rawName:"v-chakra"}],attrs:{"w":"100%","h":"100%","mr":"2"}},[_c('use',{attrs:{"href":require("@/assets/icons/play-circle-fill.svg") + "#play-circle"}})])]),_c('c-text',{attrs:{"color":"vc-orange.400","fontSize":{ lg: 'base', xl: 'lg' },"fontWeight":"600"}},[_vm._v(" Start ")])],1),_c('c-box',{attrs:{"w":{ lg: '100%', xl: '55%' }}},[_c('ProgressBar',{directives:[{name:"chakra",rawName:"v-chakra",value:({
                  w: '100%',
                }),expression:"{\n                  w: '100%',\n                }"}],attrs:{"value":_vm.stageProgress(stage.stage).totalPercentage,"valueOutside":true,"height":'10px'}})],1)],1)],1)}),1),_c('c-box',{attrs:{"borderRadius":"8px","bg":"#fff","boxShadow":"0 3px 6px rgba(186, 186, 186, 0.16)","p":"8","w":"100%","minHeight":"100px"}},[(_vm.activeStage !== 2)?_c('c-grid',{attrs:{"w":"100%","template-columns":{ base: '1fr', lg: 'repeat(2, 1fr)' },"gap":"16"}},_vm._l((_vm.activeStageData
              ? _vm.activeStageData.tour_steps
              : []),function(step,stepIndex){return _c('c-box',{key:step.target,attrs:{"mb":"4"}},[_c('StageStep',{attrs:{"step":step,"stepIndex":stepIndex,"progress":_vm.stageProgress(_vm.activeStageData.stage)[step.key]},on:{"goToStep":function($event){return _vm.onGoToStep({ parentTour: _vm.activeStageData, stepIndex })}}})],1)}),1):_c('c-stack',{attrs:{"spacing":8}},_vm._l((_vm.tour[1].tour_steps),function(page,pageIndex){return _c('c-box',{key:page.target,attrs:{"mb":"4"}},[_c('StageStep',{attrs:{"step":page,"stepIndex":pageIndex},on:{"goToStep":_vm.onGoToStep,"goToPage":function($event){return _vm.onGoToPage($event, _vm.tour[1])}}})],1)}),1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }